import React, { useState } from 'react';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { animations } from '../../helpers';

const StyledArticle = styled.article`
  @media screen and (min-width: 1024px) {
    .gatsby-image-wrapper {
      width: 42vw;
      height: 15vw;
      box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.8);
      margin: 1rem auto;
      border-radius: 1rem;

      img {
        animation: ${animations.imageAnimationEven} 30s ease-in-out infinite;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    .gatsby-image-wrapper {
      width: 47vw;
      height: 20vw;
    }
  }

  @media screen and (min-width: 1680px) {
    .gatsby-image-wrapper {
      width: 40vw;
      height: 15vw;
    }
  }
`;
const Title = styled.h1`
  @media screen and (min-width: 1024px) {
    font-size: 1.4rem;
    margin: 1rem 0;
    text-align: center;
    font-weight: normal;
  }

  @media screen and (min-width: 1280px) {
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }
`;
type listProps = {
  length: number;
};

const List = styled.ul<listProps>`
  display: grid;
  grid-column-gap: 2rem;
  ${(props) => {
    if (props.length >= 35) {
      return 'grid-template-columns: repeat(3, 1fr);';
    }
    if (props.length >= 15) {
      return 'grid-template-columns: repeat(2, 1fr);';
    }
    return 'grid-template-columns: repeat(1, 1fr); ';
  }}
  margin-left: 15vw;
  padding-left: 4rem;

  li {
    font-size: 0.7rem;
  }

  li:last-child {
    list-style: none;
  }

  li button {
    color: white;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 0.7rem;
    text-decoration: underline;
  }

  @media screen and (min-width: 1440px) {
    margin-left: 17vw;
  }

  @media screen and (min-width: 1680px) {
    margin-left: 21.5vw;

    li {
      font-size: 0.9rem;
    }

    li button {
      font-size: 0.9rem;
    }
  }

  @media screen and (min-width: 1920px) {
    margin-left: 22.7vw;
  }
`;

type Props = {
  list: Array<{ name: string }>;
  title: string;
  image: any;
};

const Course: React.FC<Props> = ({ list, title, image }) => {
  const [currentList, setCurrentList] = useState(list.slice(0, 5));
  const tall = list.length > 5;
  const [collapsed, setCollapsed] = useState(list.length > 5);
  const expand = (): void => {
    setCurrentList(list);
    setCollapsed(false);
  };
  const collapse = (): void => {
    setCurrentList(list.slice(0, 5));
    setCollapsed(true);
  };
  return (
    <StyledArticle>
      <Title>
        {title}
        <span> Courses</span>
      </Title>
      <Img fluid={image} alt={title} />
      <List length={currentList.length}>
        {currentList.map((entry) => (
          <li key={entry.name}>{entry.name}</li>
        ))}
        {tall && (
          <>
            {collapsed && (
              <li>
                <button type="button" onClick={() => expand()}>
                  More +
                </button>
              </li>
            )}
            {!collapsed && (
              <li>
                <button type="button" onClick={() => collapse()}>
                  Less -
                </button>
              </li>
            )}
          </>
        )}
      </List>
    </StyledArticle>
  );
};

export default Course;
