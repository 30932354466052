import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';
import { FluidObject } from 'gatsby-image';
import { Title } from '../../common';
import Card from './Card';
import useDesktop from '../../../hooks/useDesktop';

const StyledSection = styled.section`
  padding-top: 10rem;

  @media screen and (min-width: 1024px) {
    display: flex;
    min-height: 100vh;
    align-items: center;
    padding-top: 4rem;
  }
`;
const Cards = styled.div`
  margin: 3rem 0;

  @media screen and (min-width: 1024px) {
    flex: 0 0 calc(60% - 2rem);
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
  }

  @media screen and (min-width: 1280px) {
    flex: 0 0 calc(60% - 4rem);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
  }

  @media screen and (min-width: 1680px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export interface Product {
  node: {
    order: number;
    title: string;
    slug: string;
    list: { name: string }[];
    mainImage: { fluid: FluidObject };
    desktop: { fluid: FluidObject };
    tablet: { fluid: FluidObject };
    mobile: { fluid: FluidObject };
  };
}
export interface Props {
  title: string;
  subtitle: string;
  file: string;
  products: Product[];
}

const Products: React.FC<Props> = ({ title, subtitle, file, products }) => {
  const isDesktop = useDesktop();
  const { width } = useWindowSize();

  const getImage = (product: Product): FluidObject => {
    let image;
    if (isDesktop) {
      image = product.node.desktop.fluid;
    } else if (width > 768) {
      image = product.node.tablet.fluid;
    } else {
      image = product.node.mobile.fluid;
    }
    return image;
  };

  const cards = products.map((product, index) => (
    <Card
      image={getImage(product)}
      title={product.node.title}
      anchor={product.node.slug}
      key={product.node.order}
      list={product.node.list}
      even={index % 2 === 0}
      order={product.node.order}
    />
  ));

  return (
    <StyledSection id="whatweoffer">
      <Title heading={title} text={subtitle} file={file} />
      <Cards>{cards}</Cards>
    </StyledSection>
  );
};
export default Products;
