import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { Landing, About, Products, Contact, Product } from '../sections';
import { Props as ProductsProps } from '../sections/products/Products';
import { Props as LandingProps } from '../sections/home/Landing';
import { Props as AboutProps } from '../sections/about/About';
import { Props as ContactProps } from '../sections/contact/Contact';

interface Props {
  landingData: LandingProps;
  productsData: ProductsProps;
  aboutData: AboutProps;
  contactData: ContactProps;
}

const Fullpage: React.FC<Props> = (props) => {
  const { landingData, productsData, aboutData, contactData } = props;
  return (
    <ReactFullpage
      licenseKey="018BD15A-AE2C42DF-B4FCE7C5-CD00C382"
      scrollingSpeed={1680}
      navigation="true"
      navigationTooltips={['Home', 'Courses', 'About us', 'Contact us']}
      navigationPosition="right"
      scrollOverflow="true"
      responsiveWidth="1024"
      responsiveHeight="400"
      anchors={['home', 'courses', 'why', 'contact']}
      menu="#myMenu"
      render={() => {
        const slides = productsData.products.map((product) => (
          <div
            className="slide"
            data-anchor={product.node.slug}
            key={product.node.title}
          >
            <Product
              image={product.node.mainImage.fluid}
              title={product.node.title}
              list={product.node.list}
            />
          </div>
        ));
        return (
          <ReactFullpage.Wrapper>
            <div className="section" data-anchor="home">
              <Landing title={landingData.title} />
            </div>
            <div className="section " data-anchor="courses">
              <div className="slide" data-anchor="">
                <Products
                  title={productsData.title}
                  subtitle={productsData.subtitle}
                  file={productsData.file}
                  products={productsData.products}
                />
              </div>
              {slides}
            </div>
            <div className="section" data-anchor="why">
              <About
                subtitle={aboutData.subtitle}
                cardsData={aboutData.cardsData}
              />
            </div>
            <div className="section" data-anchor="contact">
              <Contact
                phonesUae={contactData.phonesEgypt}
                phonesEgypt={contactData.phonesEgypt}
                mail={contactData.mail}
              />
            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
  );
};

export default Fullpage;
