import React from 'react';
import styled from 'styled-components';
import { GoBook } from 'react-icons/go';
import { GiTeacher } from 'react-icons/gi';
import {
  FaMapMarkedAlt,
  FaHandsHelping,
  FaFileSignature,
  FaCheck,
} from 'react-icons/fa';
import Card from './Card';
import { Title } from '../../common';

const StyledSection = styled.section`
  padding-top: 10rem;

  @media screen and (min-width: 1024px) {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 0;
  }
`;

const Cards = styled.div`
  @media screen and (min-width: 1024px) {
    flex: 0 0 calc(60% - 2rem);
    display: grid;
    grid-column-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1680px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export interface AboutUs {
  node: {
    title: string;
    text: string;
    order: number;
  };
}

export interface Props {
  subtitle: string;
  cardsData: AboutUs[];
}

const About: React.FC<Props> = ({ subtitle, cardsData }) => {
  return (
    <StyledSection id="whymissions">
      <Title heading="Why Missions-TC ?" text={subtitle} />
      <Cards>
        {cardsData.map((cardData) => (
          <Card
            title={cardData.node.title}
            text={cardData.node.text}
            key={cardData.node.order}
            order={cardData.node.order}
          >
            {cardData.node.title === 'Locations' ? <FaMapMarkedAlt /> : ''}
            {cardData.node.title === 'Courses' ? <GoBook /> : ''}
            {cardData.node.title === 'Success' ? <FaCheck /> : ''}
            {cardData.node.title === 'Trainers' ? <GiTeacher /> : ''}
            {cardData.node.title === 'Consultancy' ? <FaHandsHelping /> : ''}
            {cardData.node.title === 'Tests' ? <FaFileSignature /> : ''}
          </Card>
        ))}
      </Cards>
    </StyledSection>
  );
};

export default About;
