import React from 'react';
import styled from 'styled-components';
import config from 'react-reveal/globals';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { graphql } from 'gatsby';
import { useWindowSize } from 'react-use';
import { FluidObject } from 'gatsby-image';
import { Layout, Navbar, SocilaIcons, BgVideo } from '../components/common';
import { Landing, About, Products, Contact } from '../components/sections';
import BackgroundVideo from '../videos/bg-video.mp4';
import Fullpage from '../components/common/Fullpage';
import { Product } from '../components/sections/products/Products';
import { AboutUs } from '../components/sections/about/About';
import posterImg from '../videos/bgposter.jpg';

config({ ssrFadeout: false });

const Wrapper = styled.main`
  margin: 0 1.2rem;

  @media screen and (min-width: 768px) {
    margin: 0 3rem;
  }

  @media screen and (min-width: 1024px) {
    margin: 0 6rem;
  }
`;

interface CoreData {
  edges: Array<{
    node: {
      siteLogo: { fluid: FluidObject };
      bgvideo: string;
      homeTitle: string;
      coursesText: string;
      schedule: {
        file: {
          url: string;
        };
      };
      aboutText: string;
      phoneUae: string[];
      phoneEgypt: string[];
      contactMail: string;
    };
  }>;
}

interface Props {
  data: {
    coreData: CoreData;
    courses: {
      edges: Product[];
    };
    aboutUs: {
      edges: AboutUs[];
    };
  };
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const logo = data.coreData.edges[0].node.siteLogo.fluid;
  const title = data.coreData.edges[0].node.homeTitle;
  const {
    coursesText,
    aboutText,
    schedule: {
      file: { url },
    },
  } = data.coreData.edges[0].node;
  const { phoneUae, phoneEgypt, contactMail } = data.coreData.edges[0].node;

  const LandingData = { title };
  const productsData = {
    title: 'What we offer ?',
    subtitle: coursesText,
    file: url,
    products: data.courses.edges,
  };
  const aboutData = {
    subtitle: aboutText,
    cardsData: data.aboutUs.edges,
  };

  const contactData = {
    phonesUae: phoneUae,
    phonesEgypt: phoneEgypt,
    mail: contactMail,
  };

  const { width } = useWindowSize();

  return (
    <Layout>
      <HelmetProvider>
        <Helmet>
          <html lang="en" />
          <meta charSet="utf-8" />
          <meta
            name="Description"
            content="We provide world-class trainings and consultancy services"
          />
          <title>Missions-TC</title>
          <link rel="canonical" href="https://www.missions-tc.com" />
        </Helmet>
      </HelmetProvider>
      <BgVideo video={BackgroundVideo as string} poster={posterImg as string} />
      <Navbar image={logo} />
      <SocilaIcons />
      <Wrapper>
        {width >= 1024 ? (
          <>
            <Fullpage
              landingData={LandingData}
              productsData={productsData}
              aboutData={aboutData}
              contactData={contactData}
            />
          </>
        ) : (
          <>
            <Landing title={title} />
            <Products
              title="What we offer ?"
              subtitle={coursesText}
              file={url}
              products={data.courses.edges}
            />
            <About subtitle={aboutText} cardsData={data.aboutUs.edges} />
            <Contact
              phonesUae={phoneUae}
              phonesEgypt={phoneEgypt}
              mail={contactMail}
            />
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    coreData: allContentfulCoreData {
      edges {
        node {
          homeTitle
          siteLogo {
            fluid(maxWidth: 250, quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          coursesText
          schedule {
            file {
              url
            }
          }
          aboutText
          phoneUae
          phoneEgypt
          contactMail
        }
      }
    }
    courses: allContentfulCourses(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          order
          title
          slug
          list: courses {
            name: content
          }
          mainImage {
            fluid(maxWidth: 920, quality: 75) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          tablet: mainImage {
            fluid(maxWidth: 640, quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mobile: mainImage {
            fluid(maxWidth: 200, quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          desktop: mainImage {
            fluid(maxWidth: 192, quality: 50) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    aboutUs: allContentfulAboutUs(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          text
          order
        }
      }
    }
  }
`;
